/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}

:root {
  --Neutral-Light-Gray: #F5F6F7;
  --Neutral-Medium-Gray: #B6BEC5;
  --Neutral-Dark-Gray: #808B98;
  --Neutral-Dark-Gray-II: #4C545C;
  --Neutral-Black: #111827;
  --New-Blue: #2975CC;
  --Neutral-Blue: #0369A1;
  --Light-New-Blue: #F3F9FF;
  --New-Green: #3CA663;
  --New-Red: #D64457;
  --New-Yellow: #DB9754;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #9ca3af;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9ca3af;
}

@layer base {

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.mat-mdc-dialog-surface {
  border-radius: 0px !important;
  background-color: transparent !important;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  background: transparent !important;
  font-size: 12px !important;
}

.mdc-tooltip {
  &.black-tooltip {
    word-break: keep-all !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    background: #808b98 !important;
    border-radius: 20px !important;
    white-space: normal !important;

    &[style*="transform-origin: left center"] {
      border-top-left-radius: 0 !important;
      margin-left: -30px !important;
      margin-top: 120px !important;
    }

    &[style*="transform-origin: left top"] {
      border-top-left-radius: 0 !important;
    }

    &[style*="transform-origin: left bottom"] {
      border-bottom-left-radius: 0 !important;
    }

    &[style*="transform-origin: right center"] {
      border-top-right-radius: 0 !important;
      margin-top: 55px !important;
    }

    &[style*="transform-origin: right top"] {
      border-top-right-radius: 0 !important;
    }

    &[style*="transform-origin: right bottom"] {
      border-bottom-right-radius: 0 !important;
    }

    &[style*="transform-origin: center top"] {
      border-top-right-radius: 0 !important;
    }

    &[style*="transform-origin: center bottom"] {
      border-bottom-right-radius: 0 !important;
    }
  }
}